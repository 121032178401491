// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1705081685346.533";
}
// REMIX HMR END

import { PlasmicComponent, PlasmicRootProvider } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import { json } from "@remix-run/server-runtime";
import { useLoaderData } from "@remix-run/react";
import * as R from 'ramda';
import { realtimeRepo } from "../model/utils.ts";
const parseYearEmptyJSON = (year, availableInvoices) => {
  return {
    year: year.toString(),
    months: R.range(0, 12).map((_, index) => {
      // 1 to 01
      const month = (index + 1).toString().padStart(2, '0');
      return {
        label: new Date(year, index).toLocaleString('en-us', {
          month: 'short'
        }),
        pdf: availableInvoices[year]?.[month] || null
      };
    })
  };
};
export const loader = async () => {
  const plasmicData = await PLASMIC.fetchComponentData('MonthReports');
  const availableInvoices = await realtimeRepo.load('available-invoices');
  return json({
    plasmicData,
    componentProps: {
      data: [parseYearEmptyJSON(2022, availableInvoices), parseYearEmptyJSON(2023, availableInvoices), parseYearEmptyJSON(2024, availableInvoices)]
    }
  });
};
export const shouldRevalidate = () => true;
export default function Index() {
  _s();
  const {
    plasmicData,
    componentProps
  } = useLoaderData();
  return <PlasmicRootProvider loader={PLASMIC} prefetchedData={plasmicData}>
            <PlasmicComponent component={'MonthReports'} componentProps={componentProps} />
        </PlasmicRootProvider>;
}
_s(Index, "rwXu70xTOiUvw5q6AurIaqOi6G0=", false, function () {
  return [useLoaderData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;